<template>
  <b-container fluid class="carousel-works">
    <b-container>
      <h3><span>{{title}}</span>{{subTitle}}</h3>
    </b-container>
    <b-container>
      <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000"
        fade
        indicators
      >
        <b-carousel-slide
          v-for="image in images"
          v-bind:key="image.id"
          v-bind:img-src="image.path"
           v-bind:img-alt="image.alt"
        ></b-carousel-slide>
      </b-carousel>
      <p>
        {{comment}}
      </p>
    </b-container>
    <hr />
  </b-container>
</template>

<script>
export default {
  name: "CarouselIsoUnder",
  props:{
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    images:{
      type: Array,
    },
    comment: {
      type: String,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.carousel-works {
  margin: 0 0 100px 0;
  .carousel {
    display: block;
    margin: 100px auto;
    width: 70%;
    @media only screen and (max-width: 720px) {
      width: 90%;
    }
    &-item {
      height: 600px;
      @media only screen and (max-width: 720px) {
        height: 100%;
      }
      img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
      }
    }
  }
  h3 {
    color: #111;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 25px;
    @media only screen and (max-width: 720px) {
      text-align: center;
    }
    span {
      padding-bottom: 5px;
      border-bottom: 2px solid #285b8467;
      @media only screen and (max-width: 720px) {
      border: none;
    }
    }
  }
  p {
    color: #111;
    font-size: 18px;
    width: 80%;
    margin: auto;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
  hr {
    margin-top: 5rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #285b8467;
    width: 80%;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
}
</style>
