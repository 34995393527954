<template>
  <div class="Works">
    <b-container fluid center>
      <h1>Nos Réalisations</h1>
    </b-container>
    <Carousel
      :title="isoCombles.title"
      :subTitle="isoCombles.subTitle"
      :images="isoCombles.images"
      :comment="isoCombles.comment"
    />
    <Carousel
      :title="isoUnder.title"
      :subTitle="isoUnder.subTitle"
      :images="isoUnder.images"
      :comment="isoUnder.comment"
    />
    <Carousel
      :title="pac.title"
      :subTitle="pac.subTitle"
      :images="pac.images"
    />
    <Carousel
      :title="panneaux.title"
      :subTitle="panneaux.subTitle"
      :images="panneaux.images"
    />
    <Carousel
      :title="fenetres.title"
      :images="fenetres.images"
    />
     <Carousel
      :title="isoMurs.title"
      :subTitle="isoMurs.subTitle"
      :images="isoMurs.images"
    />
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Home',
  components: {
    Carousel
  },
  metaInfo: {
      name: 'En Eco Nos réalisations',
      title: 'Nos réalisations',
      titleTemplate: '%s | EN ECO En Normandie',
      meta: [
                { name: 'description', content:  "Découvrez nos réalisations en eco en enormandie d'isolation à un euro en normandie, en eco en normandie vous propose ses services certifiés RGE pour isoler vos maisons par l'extérieur ou depuis vos sous-sol et combles"},
                { property: 'og:title', content: "En Eco En Normandie - Découvrez nos réalisations d'isolations d'habitations en Normandie"},
                { property: 'og:site_name', content: 'En Eco En Normandie'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content: '../assets/images/home_jumbotron.png'},
            ]
  },
   data: function() {
    return {
      pac: {
        title:"Pompes à chaleur",
        subTitle:" air/eau, gainable, air/air",
        images: [
          { id: 1, path: require("@/assets/images/pac/001.jpeg"), alt: "Images pac EN Eco en Normandie, Isolation à 1 euro RGE" },
        ],
      },
      isoUnder: {
        title:"Isolation sous",
        subTitle:"-sol",
        images: [
          { id: 1, path: require("@/assets/images/Iso_Under/001.jpeg"), alt: "Images isolations Sous-sol EN Eco en Normandie, Isolation à 1 euro RGE" },
          { id: 2, path: require("@/assets/images/Iso_Under/002.jpeg"),alt: "Images isolations Sous-sol EN Eco en Normandie, Isolation à 1 euro RGE" }, 
          { id: 3, path: require("@/assets/images/Iso_Under/003.jpeg"),alt: "Images isolations Sous-sol EN Eco en Normandie, Isolation à 1 euro RGE" }, 
          { id: 4, path: require("@/assets/images/Iso_Under/004.jpeg"),alt: "Images isolations Sous-sol EN Eco en Normandie, Isolation à 1 euro RGE" },
          { id: 5, path: require("@/assets/images/Iso_Under/005.jpeg"), alt: "Images isolations Sous-sol EN Eco en Normandie, Isolation à 1 euro RGE" }, 
        ],
        comment:"L'isolation du sous sol vous apportera un confort de vie optimal. Une bonne isolation va permettre une meilleure répartition de l'air ambiant. En hiver, il y a une conservation de la chaleur et de la fraîcheur en été."
      },
      isoCombles: {
        title:"Isolation des",
        subTitle:" combles",
        images: [
          { id: 1, path: require("@/assets/images/Iso_combles/001.jpeg"), alt: "Images isolations combles EN Eco en Normandie, Isolation à 1 euro RGE" },
          { id: 2, path: require("@/assets/images/Iso_combles/002.jpeg"),alt: "Images isolations combles EN Eco en Normandie, Isolation à 1 euro RGE" },
          { id: 3, path: require("@/assets/images/Iso_combles/003.jpeg"),alt: "Images isolations combles EN Eco en Normandie, Isolation à 1 euro RGE" },
          { id: 4, path: require("@/assets/images/Iso_combles/004.jpeg"),alt: "Images isolations combles EN Eco en Normandie, Isolation à 1 euro RGE" },
        ],
        comment:"L'isolation de vos combles est très importante pour commencer à faire des économies d'énergie et garder au maximum la chaleur de votre maison. En effet la déperdition thermique peut s'élever à 30 % de perte par le toit"
      },
      panneaux: {
        title:"Panneaux",
        subTitle:" photovoltaïques",
        images: [
          { id: 1, path: require("@/assets/images/panneaux/001.jpeg"), alt: "Images panneaux photovoltaïques combles EN Eco en Normandie, Isolation à 1 euro RGE" },
        ],
      },
      fenetres: {
        title:"Fenêtres",
         images: [
          { id: 1, path: require("@/assets/images/fenetres/001.jpeg"), alt: "Images fenêtres EN Eco en Normandie, Isolation à 1 euro RGE" },
        ],
      },
      isoMurs: {
        title:"Isolation des murs",
        subTitle:" intérieur/extérieur",
        images: [
          { id: 1, path: require("@/assets/images/iso_mur/001.jpeg"), alt: "Images isolations murs EN Eco en Normandie, Isolation à 1 euro RGE" },
          { id: 2, path: require("@/assets/images/iso_mur/002.jpeg"), alt: "Images isolations murs EN Eco en Normandie, Isolation à 1 euro RGE" },
        ],
      },
      
      
    };
  },
}
</script>

<style lang="scss" scoped>
h1 {
    margin: 50px 0 100px 0px;
    text-align: center;
    color: #111;
    font-weight: 600;
    letter-spacing: 1px;
    color: #285b84;
  }
  
</style>